import React from "react";
import Header from "./global/Header"
import style from './less/service.module.scss'
import BenefitScreen from './BenefitScreen'
import Pricing from "./Pricing"; 
import Introduction from "./Introduction";  
import Footer from "./Footer";

const Service = () => {
    return (
        <>
        <div className={style.mainContainer}>
            <Header searchBar />
            <Introduction/>
            <div className={style.benefitScreen}>
                <BenefitScreen />
            </div>
            <Pricing/>
            <Footer/>
        </div>
        </>
    )
}

export default Service;