import React from 'react';
import error404 from "../assets/Images/error/404.png";
import { Image } from 'semantic-ui-react';

const Error404 = () => {

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
    <Image size='massive' src={error404} />
    </div>
  );
};

export default Error404;
