import React, { useState, useEffect } from "react";
import style from "./less/header.module.scss";
import logo from "../../assets/Images/logo.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import io from "socket.io-client";
import { restbaseurl } from "./utils/constants"
import { Image, Segment, TransitionablePortal } from "semantic-ui-react";
import { mediaBreakpoint } from "./mediaBreakpoint";


const Header = () => {
  const [activeMenu, setActiveMenu] = useState("home");
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { screen } = useParams();

  const list = [
    { name: "Home", key: "home" },
    { name: "About Us", key: "aboutUs" },
    { name: "Pricing", key: "pricing" },
    { name: "Testimonial", key: "testimonial" },
  ];

  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(userData, 'userData');

  const socket = io(restbaseurl, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
  });

  useEffect(() => {
    const path = location.pathname === "/" ? "home" : screen;
    console.log(path, "path");
    const checkedPath = path ? path : location.pathname.substring(1);
    setActiveMenu(checkedPath);
  }, [location, screen]);

  useEffect(() => {
    if (userData?.userId) {
      console.log(userData, 'userData, ====>inheader');
      socket.emit('register', { userId: userData?.userId, role: userData?.role });
    }
  }, [userData, socket])

  const handleRedirect = (key) => {
    if (key !== "home") {
      if (key !== "services") {
        history.push(`/comingSoon/${key}`);
      } else {
        history.push(`/${key}`);
      }
    } else {
      history.push("/");
    }
    setActiveMenu(key);
    setOpen(false)
  };
  console.log(activeMenu, "activeMenu");

  const handleLogin = () => {
    const userData = JSON.parse(localStorage.getItem("userData")); // Retrieve user data from localStorage

    if (userData?.userId) {
      // Emit 'logout' event to server
      socket.emit('logout', { userId: userData.userId });

      // After emitting the logout event, disconnect the socket
      socket.disconnect();

      // Clear the localStorage (log the user out locally)
      localStorage.removeItem("userData");
      console.log("User logged out successfully");

      // Optionally, redirect to the login page after logout
      history.push('./login');
    } else {
      // If no user data, redirect to login page directly
      history.push('./login');
    }
  };

  console.log(userData, 'userData---->');

  const renderMenu = () => (
    <>
      {React.Children.toArray(
        list.map((menu) => (
          <>
            <p className={activeMenu === menu?.key ? style.activeItem : ''} onClick={() => handleRedirect(menu?.key)}>{menu?.name}</p>
          </>
        ))
      )}
    </>
  );

  const renderResponsiveMenuSection = () => (
    <>
      <div className={style.mobileMenu}>
         {renderMenu()}
      </div>
    </>
  );


  const menuIcon = () => (
    <svg width="35" height="19" viewBox="0 0 35 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClickCapture={() => setOpen(true)}>
      <path d="M1 9.25H34M1 1H34M1 17.5H17.5" stroke="black" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );

  const closeIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClickCapture={() => setOpen(false)}>
      <path d="M16 2C8.2 2 2 8.2 2 16C2 23.8 8.2 30 16 30C23.8 30 30 23.8 30 16C30 8.2 23.8 2 16 2ZM21.4 23L16 17.6L10.6 23L9 21.4L14.4 16L9 10.6L10.6 9L16 14.4L21.4 9L23 10.6L17.6 16L23 21.4L21.4 23Z" fill="#AD77F7" />
    </svg>
  );

  const isResponsive = mediaBreakpoint?.mobile > window.innerWidth;


  if (isResponsive) {
    return (
      <div className={style.mobileHeader}>
        <Image
          size="tiny"
          src={logo}
          alt="logo"
          onClickCapture={() => history.push('/')}
        />
        {menuIcon()}
        <TransitionablePortal
          transition={{ animation: "fade left", duration: 250 }}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Segment className={style.menuWrapper}>
            <div className={style.closeIcon}>{closeIcon()}</div>
            {renderResponsiveMenuSection()}
          </Segment>
        </TransitionablePortal>
      </div>
    )
  }

  return (
    <div className={style.headerWrapper}>
      <div className={style.leftWrapper}>
        <img src={logo} alt="logo" />
      </div>
      <div className={style.rightWrapper}>
        <ul>
          {list?.map((info) => (
            <li
              key={`info${info?.key}`}
              className={activeMenu === info?.key ? style.activeMenu : ""}
              onClickCapture={() => handleRedirect(info?.key)}
            >
              {info?.name}
            </li>
          ))}
        </ul>
      </div>
      <div className={style.signInBtn}>
        <p onClickCapture={handleLogin}>{userData?.userId ? 'Logout' : 'Sign in'}</p>
      </div>
    </div>
  );
};

export default Header;
