// redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import tripReducer from './tripReducer';

const store = configureStore({
  reducer: {
    trip: tripReducer,
  },
});

export default store;
