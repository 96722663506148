import React from 'react';
import style from './less/specialServices.module.scss';

const SpecialServices = ({ setModal }) => {
  const fastIcon = () => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="50" cy="50" r="50" fill="#AD77F7"/>
      <path opacity="0.2" d="M100 50C100 56.5661 98.7067 63.0679 96.194 69.1342C93.6812 75.2005 89.9983 80.7124 85.3553 85.3553C80.7124 89.9983 75.2004 93.6812 69.1342 96.194C63.0679 98.7067 56.5661 100 50 100C43.4339 100 36.9321 98.7067 30.8658 96.194C24.7995 93.6812 19.2876 89.9983 14.6447 85.3553C10.0017 80.7124 6.31876 75.2004 3.80602 69.1342C1.29329 63.0679 -5.74026e-07 56.5661 0 50L50 50H100Z" fill="#AD77F7"/>
      <path d="M47.7644 35.4058C37.9401 35.4058 30 43.3683 30 53.1702C30 62.9944 37.9626 70.9346 47.7644 70.9346C57.5886 70.9346 65.5288 62.972 65.5288 53.1702H47.7644V35.4058Z" fill="#AD77F7" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M53.0356 30C62.8599 30 70.8 37.9626 70.8 47.7644H53.0356V30Z" fill="#AD77F7" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
  const bookingIcon = () => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="50" cy="50" r="50" fill="#AD77F7"/>
      <path opacity="0.2" d="M100 50C100 63.2608 94.7322 75.9785 85.3553 85.3553C75.9785 94.7322 63.2608 100 50 100C36.7392 100 24.0215 94.7322 14.6447 85.3553C5.26785 75.9785 2.00233e-06 63.2608 0 50L50 50H100Z" fill="#AD77F7"/>
      <path d="M66.6202 66.6001H35.2002C33.0002 66.6001 31.2002 64.8001 31.2002 62.6001V40.6201C31.2002 38.4201 33.0002 36.6201 35.2002 36.6201H66.6202C68.8202 36.6201 70.6202 38.4201 70.6202 40.6201V62.6001C70.6202 64.8001 68.8402 66.6001 66.6202 66.6001Z" fill="#AD77F7" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M31.2002 44.2002H70.6202" stroke="#DCD9EB" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M41.4805 39.7802V33.2002" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M60.3403 39.7802V33.2002" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );

  const comfortableIcon = () => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="50" cy="50" r="50" fill="#AD77F7"/>
    <path opacity="0.2" d="M100 50C100 63.2608 94.7322 75.9785 85.3553 85.3553C75.9785 94.7322 63.2608 100 50 100C36.7392 100 24.0215 94.7322 14.6447 85.3553C5.26785 75.9785 2.00233e-06 63.2608 0 50L50 50H100Z" fill="#AD77F7"/>
    <path d="M55.1351 64.2348L49.5668 67.3115L43.9985 64.2131C37.8018 60.7898 33.9668 54.2681 33.9668 47.1831V31.7998H65.1451V47.1831C65.1451 54.2681 61.3101 60.7898 55.1351 64.2348Z" fill="#AD77F7" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.7817 46.9883L48.1801 51.4083L55.3517 44.2583" stroke="white" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
  );

  const peopleIcon = () => (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="50" cy="50" r="50" fill="#AD77F7"/>
      <path opacity="0.2" d="M100 50C100 56.5661 98.7067 63.0679 96.194 69.1342C93.6812 75.2005 89.9983 80.7124 85.3553 85.3553C80.7124 89.9983 75.2004 93.6812 69.1342 96.194C63.0679 98.7067 56.5661 100 50 100C43.4339 100 36.9321 98.7067 30.8658 96.194C24.7995 93.6812 19.2876 89.9983 14.6447 85.3553C10.0017 80.7124 6.31876 75.2004 3.80602 69.1342C1.29329 63.0679 -5.74026e-07 56.5661 0 50L50 50H100Z" fill="#AD77F7"/>
      <path d="M61.1986 44.6668C63.4428 41.7734 62.9165 37.6085 60.0231 35.3643C57.1296 33.1201 52.9647 33.6463 50.7205 36.5398C48.4763 39.4332 49.0026 43.5981 51.896 45.8423C54.7894 48.0866 58.9543 47.5603 61.1986 44.6668Z" fill="#AD77F7" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M43.1531 64.8631C43.1531 57.7998 48.8947 52.0581 55.9581 52.0581C63.0214 52.0581 68.7631 57.7998 68.7631 64.8631" fill="#AD77F7"/>
      <path d="M42.6634 44.7974C43.8731 42.5403 43.0241 39.73 40.767 38.5203C38.51 37.3106 35.6996 38.1596 34.4899 40.4167C33.2802 42.6737 34.1293 45.4841 36.3863 46.6938C38.6434 47.9035 41.4537 47.0544 42.6634 44.7974Z" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M29.6335 59.5548C29.6335 54.6148 33.6419 50.6064 38.5819 50.6064C42.2435 50.6064 45.3852 52.7948 46.7719 55.9581" stroke="#AD77F7" stroke-width="2.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );

  const list = [
    {
      title: "Fast and simple",
      icon: fastIcon(),
      desc: "You can manage all your tasks in our first and very helpful feature for your task",
    },
    {
      title: "Booking",
      icon: bookingIcon(),
      desc: "Can help you to make detailed planning and also very real time for yout time work",
    },
    {
      title: "Comfortable ",
      icon: comfortableIcon(),
      desc: "We will find out what you are working on or there is a problem with your task manager",
    },
    {
      title: "2K Peoples happy",
      icon: peopleIcon(),
      desc: "Create a team online and you can discuss with our professional team",
    },
  ]

  const renderList = () => (
    list?.map((data, i) => (
      <div key={`info${i}`} className={style.informationWrapper}>
         {data?.icon}
         <h4>{data?.title}</h4>
         <p>{data?.desc}</p>
      </div>
    ))
  )

  return (
    <div className={style.specialServices}>
     <div className={style.leftContainer}>
      <h1>We will make you very special with our service</h1>
      <p>There are many benefits that you will get at Raj on call Driver Service, and for sure you will be comfortable and safe with us with great facilities and benefits</p>
      <p className={style.bookNowBtn} onClick={() => setModal(true)}>Book now</p>
     </div>
     <div className={style.rightContainer}>
      {renderList()}
     </div>
    </div>
  );
};

export default SpecialServices;
