import React from 'react';
import styles from './less/carLoader.module.scss';
import car from "../../assets/Images/booking/car.png"
import tire1 from "../../assets/Images/booking/tire1.png"
import tire2 from "../../assets/Images/booking/tire2.png"
import road from "../../assets/Images/booking/road.png"
import { Image } from 'semantic-ui-react';

const CarLoader = () => {
  return (
    <div className={styles.loader}>
      <svg width="580" height="250" viewBox="0 0 580 180" fill="none" xmlns="http://www.w3.org/2000/svg">
        <foreignObject x="0" y="0" width="100%" height="100%">
          <div>
             <Image src={car}  />
          </div>
        </foreignObject>
        <foreignObject x="52" y="72" width="100" height="110">
             <Image src={tire1} size='large' className={styles.wheelImg} />
        </foreignObject>
        <foreignObject x="65%" y="72" width="100" height="110">
             <Image src={tire2} size='large' className={styles.wheelImg}/>
        </foreignObject>
      </svg>
       {/* <Image src={road}  /> */}
       <div className={styles.road}>

       </div>
    </div>
  );
};

export default CarLoader;
