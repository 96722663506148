import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Clock from "react-clock";
import { Button, Input } from "semantic-ui-react";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./less/customDateTimePicker.module.scss";
import "./less/customDateAndTime.scss";

const CustomDateTimePicker = ({setSchedule, setStep}) => {
  // Initialize selectedTime with the current time
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedTimingMode, setSelectedTimingMode] = useState(0);
  const [amPm, setAmPm] = useState(selectedTime.getHours() >= 12 ? "PM" : "AM");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedTime = new Date(selectedTime);
    // let hours = updatedTime.getHours();

    if (name === "hours") {
      let hourValue = parseInt(value, 10);
      if (amPm === "PM" && hourValue < 12) hourValue += 12;
      if (amPm === "AM" && hourValue === 12) hourValue = 0;
      updatedTime.setHours(hourValue);
    } else if (name === "minutes") {
      updatedTime.setMinutes(value);
    }

    setSelectedTime(updatedTime);
  };

  const toggleAmPm = () => {
    const updatedTime = new Date(selectedTime);
    let hours = updatedTime.getHours();

    if (amPm === "AM") {
      setAmPm("PM");
      if (hours < 12) updatedTime.setHours(hours + 12);
    } else {
      setAmPm("AM");
      if (hours >= 12) updatedTime.setHours(hours - 12);
    }

    setSelectedTime(updatedTime);
  };

  const handleSetPickupTime = () => {
   setSchedule({date: selectedDate.toDateString(), time: selectedTime})
   setStep(3);
  };

  return (
    <div className={styles.customDateTimePicker}>
      <div className={styles.headerWrapper}>
        <h3>When do you need driver?</h3>
        <Button
          onClick={() => setSelectedTimingMode(0)}
          className={`${selectedTimingMode === 0 ? styles.activeBtn : ""} ${styles.timingBtn}`}
        >
          Schedule
        </Button>
        <Button
          onClick={() => setSelectedTimingMode(1)}
          className={`${selectedTimingMode === 1 ? styles.activeBtn : ""} ${styles.timingBtn}`}
        >
          Now
        </Button>
      </div>

      <div className={styles.selectedDatetime}>
        <p>Pickup Time: {`${selectedDate.toDateString()}, ${selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} ${amPm}`}</p>
      </div>
      <hr />
      <div className={styles.timeAndDateWrapper}>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="EEE dd MMM"
          className={` datePicker ${styles.customDatepicker}`}
        />

        <div className={styles.customTimepicker}>
          <div className={styles.clockWrapper}>
            <Clock
              value={selectedTime}
              onChange={handleTimeChange}
              renderNumbers={true}
            />
            <Button onClick={toggleAmPm} className={styles.ampmBtn}>
                {amPm}
              </Button>
          </div>
          <div className={styles.inputWrapper}>
            <Input
              type="number"
              name="hours"
              value={selectedTime.getHours() % 12 || 12} 
              onChange={handleInputChange}
              min="1"
              max="12"
              className={styles.timeInput}
            />
            <span>:</span>
            <Input
              type="number"
              name="minutes"
              value={selectedTime.getMinutes()} 
              onChange={handleInputChange}
              min="0"
              max="59"
              className={styles.timeInput}
            />
          </div>
        </div>
      </div>
      <hr />
      <Button className={styles.confirmBtn} onClick={handleSetPickupTime}>
        Set Pickup Time
      </Button>
    </div>
  );
};

export default CustomDateTimePicker;
