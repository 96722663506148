import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import Service from "./screens/Service";
import Dashboard from "./screens/Dashboard";
import ComingSoon from "./screens/global/ComingSoon";
import "./assets/less/main.scss";
import LoginScreen from "./screens/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen";
import BookingScreen from "./screens/BookingScreen";
import DriverDashboard from "./screens/DriverScreen";
import Error401 from "./screens/Error401";
import Error404 from "./screens/Error404";
import Error500 from "./screens/Error500";
import DriverSideTripInfo from "./screens/DriverSideTripInfo";
import { useSelector } from 'react-redux';

// import LocationComponent from "./screens/global/LocationScreen"

const App = () => {
  const [location, setLocation] = useState({ lat: null, lng: null });

  useEffect(() => {
    // const storedLocation = localStorage.getItem('userLocation');

    // if (storedLocation) {
    //   setLocation(JSON.parse(storedLocation));
    // } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setLocation(userLocation);
            localStorage.setItem('userLocation', JSON.stringify(userLocation));
          },
          (error) => {
            console.error('Error fetching location:', error.message);
            // Handle error if needed
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    // }
  }, []);

  
  return (
    <>
      <Router>
        <DriverDashboard />
        <Switch>
          <Route exact path="/">
            <HomeScreen location={location} />
          </Route>
          <Route exact path="/login">
            <LoginScreen />
          </Route>
          <Route exact path="/signUp">
            <SignUpScreen />
          </Route>
          <Route exact path="/services">
            <Service />
          </Route>
          <Route exact path="/administrationScreen">
            <Dashboard />
          </Route>
          <Route exact path="/comingSoon/:screen">
            <ComingSoon />
          </Route>
          <Route exact path="/bookingScreen">
            <BookingScreen />
          </Route>
          <Route exact path="/driverScreen">
            <DriverDashboard />
          </Route>
          <Route exact path="/tripScreen">
            <DriverSideTripInfo />
          </Route>
          <Route exact path="/404">
            <Error404 />
          </Route>
          <Route exact path="/unauthorized">
            <Error401 />
          </Route>
          <Route exact path="/500">
            <Error500 />
          </Route>
        </Switch>
      </Router>
      {/* <LocationComponent /> */}
    </>
  );
};

export default App;
