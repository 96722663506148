export const protocol = process.env.REACT_APP_PROTOCOL;
export const domain = process.env.REACT_APP_DOMAIN;
export const port = process.env.REACT_APP_PORT;
export const reactport = process.env.REACT_APP_REACTPORT;

export const uploadsport = process.env.REACT_APP_UPLOADSPORT;
export const filemanagerapiport = process.env.REACT_APP_FILEMANAGERAPIPORT;
export const reactcontext = process.env.REACT_APP_REACTCONTEXT;
export const apicontext = process.env.REACT_APP_APICONTEXT;
export const cookiedomain = process.env.REACT_APP_COOKIEDOMAIN;

export const restbaseurl = process.env.REACT_APP_RESTBASEURL;
export const cdnurl = process.env.REACT_APP_CDNURL;
export const payumoneyurl = process.env.REACT_APP_PAYUMONEYURL;
export const reacturl = process.env.REACT_APP_REACTURL;
export const fileMangerurl = process.env.REACT_APP_FILEMANGERURL;
export const fileMangerContext = process.env.REACT_APP_FILEMANGERCONTEXT;
export const uploadsContext = process.env.REACT_APP_UPLOADSCONTEXT;
export const contexPath = process.env.REACT_APP_CONTEXPATH;
export const timerRefreshInterval = 120000;
export const ckeditorurl = {
  basic: 'https://cdn.ckeditor.com/4.14.1/basic/ckeditor.js',
};

export const execprotocol = process.env.REACT_APP_EXEC_PROTOCOL;
export const execdomain = process.env.REACT_APP_EXEC_DOMAIN;
export const execport = process.env.REACT_APP_EXEC_PORT;
export const execurl = process.env.REACT_APP_EXECBASEURL;
