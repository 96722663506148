import React, { useState } from "react";
import style from "./less/questionAnswers.module.scss";
import { Icon } from "semantic-ui-react";

const QuestionAnswers = () => {
  const list = [
    {
        question: "Why should I choose Raj on Call Driver Service?",
        answer:
            "Raj on Call Driver Service stands out with over 2K satisfied customers who appreciate our exceptional service and affordable prices. We prioritize your comfort, safety, and satisfaction."
    },
    {
        question: "Why is Raj on Call Driver Service cheap?",
        answer:
            "We believe in providing quality service at affordable prices. Our efficient operations and customer-centric approach allow us to keep costs low while maintaining high standards."
    },
    {
        question: "Why is Raj on Call Driver Service so friendly?",
        answer:
            "Our drivers are not only professional but also friendly and courteous. We strive to create a pleasant and welcoming experience for all our passengers, ensuring a comfortable journey every time."
    },
    {
        question: "24/7 customer services?",
        answer:
            "Yes, we offer 24/7 customer service to assist you with your needs at any time of the day or night. Whether you need to book a ride, have a query, or require assistance, our team is always here to help."
    },
    {
        question: "How to order Raj on Call Driver Service?",
        answer:
            "Ordering a ride with Raj on Call Driver Service is easy. You can book a ride through our website, mobile app, or by calling our customer service hotline. Simply provide your pickup location, drop-off location, and desired time, and we'll take care of the rest."
    }
];

  const [activeQuestion, setActiveQues] = useState(0);

  const handleActiveQues = (idx) => {
    if (activeQuestion === idx) {
      setActiveQues(null);
    } else {
      setActiveQues(idx);
    }
  };

  const renderQuestionAnswers = () => {
    return list?.map((info, i) => {
      return (
        <div className={`${style.questionContainer} ${activeQuestion === i && style.activeQuestion}`}>
          <h5 onClickCapture={() => handleActiveQues(i)}>
            {info?.question}
            <Icon
              name={
                activeQuestion === i
                  ? "chevron circle up"
                  : "chevron circle down"
              }
            />
          </h5>
          <p
            className={`${style.answer} ${
              activeQuestion !== null && activeQuestion === i && style.activeAns
            }`}
          >
            {info?.answer}
          </p>
          {/* {i < list?.length - 1 && <hr />} */}
        </div>
      );
    });
  };
  return (
    <>
      <div className={style.questionAnswersWrapper}>
          <h1>Frequently Asked Questions   (FAQ)</h1>
          <p>Our browser faq below, if you can't find the answer in Gready</p>
          <div className={style.questionsWrapper}>
            {renderQuestionAnswers()}
        </div>
      </div>
    </>
  );
};

export default QuestionAnswers;
