import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import style from "./less/loginScreen.module.scss";
import leftImage from "../assets/Images/login.png";
import { Image, Form, Checkbox, Input, Button } from "semantic-ui-react";
import GlobalService from "./services/GlobalService";
import { resturls } from "./global/utils/apiurls";

const SignUpScreen = () => {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    rePassword: "",
    mobileNumber: "",
    role: "user",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    rePassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please re-enter your password"),
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Mobile number is required"),
  });

  const handleSubmit = (values) => {
    const { name, email, password, mobileNumber, role } = values;
    console.log("Submitted role:", role);

    const userObj = {
      userName: name,
      email: email,
      password: password,
      mobileNumber: mobileNumber,
      role,
    };

    console.log(userObj);

    GlobalService.generalSelect(
      (response) => {
        const { message } = response;
        if (message === "success") {
          // Handle successful sign-up
          // window.location.href = "/login";
        }
      },
      resturls.signUp,
      userObj,
      "POST"
    );
  };

  return (
    <div className={style.loginWrapper}>
      <div className={style.container}>
        <div className={style.imageWrapper}>
          <Image src={leftImage} alt="Illustrative image" />
        </div>
        <div className={style.contentWrapper}>
          <div className={style.signInContent}>
            <h3>Create an account</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit }) => (
                <Form onSubmit={handleSubmit} className={style.form}>
                  <Form.Field
                    control={Input}
                    label="Name"
                    placeholder="Enter your name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={style.inputWrapper}
                    error={
                      touched.name && errors.name ? { content: errors.name, pointing: "below" } : null
                    }
                  />

                  <Form.Field
                    control={Input}
                    label="Email"
                    placeholder="Enter your email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={style.inputWrapper}
                    error={
                      touched.email && errors.email ? { content: errors.email, pointing: "below" } : null
                    }
                  />

                  <Form.Field
                    control={Input}
                    label="Mobile Number"
                    placeholder="Enter your mobile number"
                    name="mobileNumber"
                    value={values.mobileNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={style.inputWrapper}
                    error={
                      touched.mobileNumber && errors.mobileNumber ? { content: errors.mobileNumber, pointing: "below" } : null
                    }
                  />

                  <Form.Field
                    control={Input}
                    label="Password"
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={style.inputWrapper}
                    error={
                      touched.password && errors.password ? { content: errors.password, pointing: "below" } : null
                    }
                  />

                  <Form.Field
                    control={Input}
                    label="Re-enter Password"
                    type="password"
                    placeholder="Re-enter your password"
                    name="rePassword"
                    value={values.rePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={style.inputWrapper}
                    error={
                      touched.rePassword && errors.rePassword ? { content: errors.rePassword, pointing: "below" } : null
                    }
                  />

                  <Form.Field>
                    <Checkbox
                      label="Driver Only"
                      checked={values.role === "driver"}
                      onChange={() => setFieldValue("role", values.role === "driver" ? "user" : "driver")}
                    />
                    <Checkbox
                      label="Car with Driver"
                      checked={values.role === "car_driver"}
                      onChange={() => setFieldValue("role", values.role === "car_driver" ? "user" : "car_driver")}
                    />
                  </Form.Field>

                  <Button type="submit" primary>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
