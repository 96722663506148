// redux/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tripSlice = createSlice({
  name: 'trip',
  initialState: {
    tripInfo: {},
  },
  reducers: {
    setTripInfo: (state, action) => {
     console.log(action.payload, 'action.payload');
      state.tripInfo = action.payload;
    },
  },
});

export const { setTripInfo } = tripSlice.actions;
export default tripSlice.reducer;
