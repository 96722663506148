import React from 'react';
import style from './less/benefit.module.scss';

const benefitsData = [
  {
    id: 1,
    title: 'Smart Home Solutions',
    description: 'Healthcare edu robotics offer smart home solutions for automated healthcare monitoring and assistance, enhancing patient care and independence.'
  },
  {
    id: 2,
    title: 'Smart Home Automation',
    description: 'With healthcare edu robotics, smart home automation simplifies daily tasks and enhances comfort, integrating seamlessly into your routine.'
  },
  {
    id: 3,
    title: 'Easy Control and Accessibility',
    description: 'Enjoy easy control and accessibility with healthcare edu robotics, allowing users to manage health-related tasks efficiently and conveniently.'
  },
  {
    id: 4,
    title: 'Enhanced Home Security',
    description: 'Healthcare edu robotics ensure enhanced home security by monitoring vital signs and alerting caregivers or emergency services when necessary.'
  },
  {
    id: 5,
    title: 'Seamless Device Integration',
    description: 'Integrate healthcare edu robotics seamlessly with other smart devices for holistic health management and a unified smart home experience.'
  },
  {
    id: 6,
    title: 'Support and Updates',
    description: 'Benefit from continuous support and updates with healthcare edu robotics, ensuring your system remains efficient, secure, and up-to-date.'
  }
];

const BenefitScreen = () => {
  return (
    <div className={style.benefits}>
      <div className={style.title}>Why Choose Us</div>
      <div className={style.subTitleWrapper}>
        <div className={style.subTitle}>The Benefits of Healthcare Edu Robotics</div>
        <span className={style.learnMoreBtn}>Learn More</span>

      </div>
      <div className={style.cards}>
        {benefitsData.map((benefit) => (
          <div key={benefit.id} className={style.card}>
            <div className={style.cardNumber}>{benefit.id < 10 ? `0${benefit.id}.` : `${benefit.id}.`}</div>
            <h4 className={style.cardTitle}>{benefit.title}</h4>
            <p className={style.cardDescription}>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitScreen;
