import React, { useState, useCallback } from "react";
import style from "./less/loginScreen.module.scss";
import leftImage from "../assets/Images/login.png";
import { Image, Input, Icon, Button, Form } from "semantic-ui-react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import GlobalService from "./services/GlobalService";
import { resturls } from "./global/utils/apiurls";
import io from "socket.io-client";
import { restbaseurl } from "./global/utils/constants";

const LoginScreen = () => {
  const [activeItem, setActiveBtn] = useState(0);
  const [loginStatus, setLoginStatus] = useState("");

  const socket = io(restbaseurl, {
    reconnection: false,
  });

  const validationSchema = Yup.object({
    userName: Yup.string()
      .required("Email or Phone Number is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = useCallback((values) => {
    const obj = {
      userName: values.userName,
      password: values.password,
    };

    GlobalService.generalSelect(
      (respdata) => {
        const { data, message } = respdata;
        if (message === "success") {
          console.log(data, "data----->>>>>");
          localStorage.setItem("userData", JSON.stringify(data));
          socket.emit("register", { userId: data?.userId, role: data?.role });
          if (data.role === "admin") {
            window.location.href = "/administrationScreen";
          } else {
            window.location.href = "/";
          }
        } else {
          setLoginStatus("mismatch");
        }
      },
      resturls.login,
      obj,
      "POST"
    );
  }, [setLoginStatus]);

  const renderSignUpContent = () => {
    if (activeItem === 1) {
      return (
        <div className={style.signInContent}>
          <h3>Sign in</h3>
          <Formik
            initialValues={{ userName: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <div className={style.inputWrapper}>
                  <Field
                    as={Input}
                    name="userName"
                    placeholder="Email or Phone Number"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.userName && errors.userName ? true : false}
                  />
                  <ErrorMessage name="userName" component="div" className={style.errorMessage} />
                  <Field
                    as={Input}
                    icon
                    name="password"
                    type="password"
                    placeholder="Enter Your Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && errors.password ? true : false}
                  >
                    <input />
                    <Icon name="eye slash outline" />
                  </Field>
                  <ErrorMessage name="password" component="div" className={style.errorMessage} />
                  <span className={style.forgetpasswordText}>Forget password?</span>
                </div>
                <p className={style.signinBtn} onClickCapture={handleSubmit}>Sign in</p>
              </Form>
            )}
          </Formik>
        </div>
      );
    }

    return (
      <>
        <div className={style.textContent}>
          <h3>Welcome</h3>
          <p>Have a better sharing experience</p>
        </div>
        <div className={style.btnWrapper}>
          <p onClickCapture={() => window.location.href = "/signUp"} className={activeItem === 0 ? style.activeBtn : ''}>
            Create an account
          </p>
          <p onClickCapture={() => setActiveBtn(1)} className={activeItem === 1 ? style.activeBtn : ''}>
            Sign in
          </p>
        </div>
      </>
    );
  };

  return (
    <div className={style.loginWrapper}>
      <div className={style.container}>
        <div className={style.imageWrapper}>
          <Image src={leftImage} alt="leftImage" />
        </div>
        <div className={style.contentWrapper}>
          {renderSignUpContent()}
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
