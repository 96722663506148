
export const resturls = {
    login: "user/login",
    signUp: "user/signUp",
    sendOTP: "user/sendOTP",
    obtainLocationList: "booking/obtainLocationList",
    checkDistance: "booking/checkDistance",
    acceptTrip: "booking/acceptTrip",
    obtainUserList: "admin/obtainUserList",
};
