import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { Modal, Button, Segment, Icon, Image } from 'semantic-ui-react';
import style from "./less/bookingModal.module.scss";
import mapImage from  '../assets/Images/booking/map.png';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import Header from './global/Header';

const DriverSideTripInfo = () => {
  const userDetail = localStorage.getItem("userData");
  const userObject = JSON.parse(userDetail)

  const tripInfo = useSelector((state) => state.trip.tripInfo);
  console.log(tripInfo, 'tripLog----->');
  

  const capitalizeFirstLetter = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  

  return (
    <div>
      <Header />
      <div className={style.activeRide}>
        <div className={style.leftWrapper}>
          <Segment className={style.tripCard}>
            <h5>TripId : {tripInfo?.tripId}</h5>
            <div className={style.tripLoaction}>
               <h6>From</h6><p>{tripInfo?.pickupLocation?.address}</p>
            </div>
            <div className={style.tripLoaction}>
              <h6>To</h6><p>{tripInfo?.dropoffLocation?.address}</p>
            </div>
            <div className={style.tripLoaction}>
              <h6>Cost</h6><p>Rs.{tripInfo?.fare} /-</p>
            </div>
          </Segment>
        </div>
        <div className={style.bookedInfo}>
            <div className={style.mapWrapper}>
              <Image src={mapImage} size="massive" />
            </div>
            <Segment className={style.driverInfoCard}>
              <div className={style.leftInfo}>
                <Avatar className={style.userAvatar}  size="85" round={true}  name={capitalizeFirstLetter(tripInfo?.customer?.username)} />
                <div>
                  <p>Customer</p>
                  <h5>{tripInfo?.customer?.username}</h5>
                  <p>ID: {tripInfo?.customerId}</p>
                </div>
              </div>
              <div className={style.rightInfo}>
                <Icon name="phone volume" size="huge" />
                <div>
                  <p>Contact No</p>
                  <h5>{tripInfo?.customer?.mobileNumber}</h5>
                </div>
              </div>
            </Segment>
        </div>
      </div>
    </div>
  );
};

export default DriverSideTripInfo;
