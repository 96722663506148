import Axios from "axios";
import ls from "local-storage";
import { restbaseurl, cdnurl } from "./constants";
import { updateUserInfo, clearCookies } from "./index";

class RestDataSource {
  static async SendRequest(method, url, callback, data = {}, urlType = "api") {
    try {
      const req = Axios.request({
        baseURL: (urlType === 'cdn' ? cdnurl : restbaseurl),
        // baseURL: "https://raj-calltaxi-backend.onrender.com",
        method,
        url,
        data,
        // withCredentials: true,
      });

      const resp = await req;
      const respdata = resp.data;
      if (resp.status === 200) {
        const { estatus, emessage } = respdata;
        if (estatus === true && emessage === "success") {
          if (
            String(url).search("user/login") !== -1 ||
            String(url).search("secure/pluginSSOLogin") !== -1 ||
            String(url).search("a/n") !== -1
          ) {
            const {
              data: { valid, isBlocked },
            } = respdata;
            if (valid === true && isBlocked === false) {
              console.log("Login Successful");
              updateUserInfo(respdata.c1);
            } else {
              console.log("Invalid Credentials");
            }
          } else {
            const cookieObj = ls.get("_c1");
            if (cookieObj !== undefined && cookieObj !== null) {
              const now = new Date();
              if (now.getTime() <= cookieObj.expiry) {
                cookieObj.expiry = now.getTime() + 1200000;
                ls.set("_c1", cookieObj);
              } else {
                ls.remove("_c1");
                window.localStorage.setItem("isEventClosed", "");
              }
            }
          }
        } else {
          console.log("Regular flow");
        }
      }
      callback(resp.data);
    } catch (err) {
      console.error('API request failed:', err);
      if (err.response) {
          const status = err.response.status;
          const message = err.response.data?.message || 'An error occurred';
          
          if (status === 401) {
              if (message === "Invalid token") {
                  clearCookies();
                  document.location.href = "/login";
              } else {
                  document.location.href = "/unauthorized";
              }
          } else if (status === 500) {
              document.location.href = "/500";
          }
      } else {
          console.error('Network Error:', err.message);
      }
  }
  
  }

  static async GetData(
    callback,
    url,
    data = {},
    method = "GET",
    urlType = "api"
  ) {
    RestDataSource.SendRequest(method, url, callback, data, urlType);
  }

  static async Save(callback, url, data) {
    RestDataSource.SendRequest("post", url, callback, data);
  }

  static async Update(callback, url, data) {
    RestDataSource.SendRequest("put", `${url}/${data.id}`, callback, data);
  }

  static async Delete(callback, url, data) {
    RestDataSource.SendRequest("delete", `${url}/${data.id}`, callback, data);
  }

  static async Put(callback, url, data) {
    RestDataSource.SendRequest("put", url, callback, data);
  }
}

export default RestDataSource;
