import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { Modal, Button, Segment, Icon, Image } from 'semantic-ui-react';
import GlobalService from "./services/GlobalService";
import { useHistory } from "react-router-dom";
import { resturls } from "./global/utils/apiurls";
import { restbaseurl } from "./global/utils/constants"
import { useDispatch } from 'react-redux';
import { setTripInfo } from './redux/tripReducer';

const DriverScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  console.log('History:', history);
  const [tripDetail, setTripDetail] = useState([]);
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const userDetail = localStorage.getItem("userData");
  const userObject = JSON.parse(userDetail)

  useEffect(() => {
    // Ask for notification permission on mount
    if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        setNotificationPermission(permission);
        if (permission === 'granted') {
          console.log('Notification permission granted');
        } else if (permission === 'denied') {
          console.log('Notification permission denied');
        }
      });
    } else if (Notification.permission === 'granted') {
      console.log('Notification permission already granted');
    } else {
      console.log('Notification permission already denied');
    }
  }, []);
  

  useEffect(() => {
    // const newSocket = io('http://localhost:4000');
    const newSocket = io(restbaseurl);

    newSocket.on('connect', () => {
      console.log('Socket connected');
    });


    const handleNewMessage = (response) => {
      console.log('New booking notification received:', response);
      const userData = JSON.parse(localStorage.getItem("userData"));
      const matchedUser = response?.activeDriver?.includes(userData?.userId);
      
      if (matchedUser && notificationPermission === 'granted') {
          setCurrentNotification(response); // Set current notification for modal
          setModalOpen(true); // Open the modal
        
        // Show browser notification
        new Notification("New Booking Request", {
          body: `Pickup at: ${response.message}`,
        });
      }
    };

    newSocket.on('newBookingNotification', handleNewMessage);

    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Cleanup socket connection on component unmount
    return () => {
      newSocket.disconnect();
    };
  }, [notificationPermission]); // Dependency on notificationPermission to ensure it's available

  const handleAccept = () => { 
    if (currentNotification && userObject) {
        const obj = {
            driverId: userObject?.userId,
            customerId: currentNotification?.tripDetails?.customerId,
            status: 'accepted'
        };
        setModalOpen(false); // Close the modal

        GlobalService.generalSelect(
            (respdata) => {
                const { data, message } = respdata;
                if (message === "success") {
                    console.log(data, 'handleAccept');
                    setCurrentNotification(null);
                    dispatch(setTripInfo(data), () => {
                    });
                    setTimeout(() => {
                      console.log(history, 'Navigating to /tripScreen'); // Debug log
                      if (history) {
                        history.push("/tripScreen");
                    }
                  }, 1000);
                    
                }
            },
            resturls.acceptTrip,
            obj,
            "POST",
        );
    }
};


  const handleReject = () => {    
    if (currentNotification) {
      const obj = {userId: userDetail?.userId, bookingId: currentNotification.bookingId, status: 'rejected' };
      // Send acceptance response to backend
      GlobalService.generalSelect(
        (respdata) => {
          const { data, message } = respdata;
          if (message === "success") {
            console.log(data, 'data----->>>>>');
          }
        },
        resturls.acceptTrip,
        obj,
        "POST",
      );
      console.log('Rejected booking:', currentNotification.bookingId);
    }
    setModalOpen(false);
    setCurrentNotification(null);
  };

  console.log(tripDetail, 'tripDetail');
  

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>New Booking Request</Modal.Header>
        <Modal.Content>
          <p>Pickup Location: {currentNotification?.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={handleReject}>
            Reject
          </Button>
          <Button color="green" onClick={handleAccept}>
            Accept
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default DriverScreen;
