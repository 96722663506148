import React, { useState, useCallback, useEffect } from "react";
import style from "./less/bookingModal.module.scss";
import { Icon, Image, Input, Segment } from "semantic-ui-react";
import CustomDateTimePicker from "./global/CustomDateTimePicker";
import CarLoader from "./global/CarLoader";
import GlobalService from "./services/GlobalService";
import { resturls } from "./global/utils/apiurls";
import { toIcon, fromIcon } from "./global/IconsList";
import debounce from "./global/utils/debounce";  // Assuming debounce is moved to utils folder
import { useHistory } from "react-router-dom";
import Header from "./global/Header";
import { io } from 'socket.io-client';
import { restbaseurl } from "./global/utils/constants";
import mapImage from '../assets/Images/booking/map.png';
import Avatar from 'react-avatar';
import carImg from "../assets/Images/Car.png";
import driverImg from "../assets/Images/driver.png";

const BookingScreen = () => {
  const history = useHistory();
  const [schedule, setSchedule] = useState({ date: "", time: "" });
  const [input, setInput] = useState({ from: "", to: "" });
  const [booking, setBooking] = useState(false);
  const [tripInfo, setTripInfo] = useState(false);
  const [locationList, setLocations] = useState([]);
  const [focusedField, setFocusedField] = useState("");
  const [rideType, setRideType] = useState("car");
  const [position, setPosition] = useState({ from: {}, to: {} });
  const [step, setStep] = useState(1); // Step state to track the booking stage
  const userData = JSON.parse(localStorage.getItem("userData"));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prevInput) => ({ ...prevInput, [name]: value }));
    if (value?.length > 0) {
      fetchLocations(value, name);
    }
  };

  useEffect(() => {
    const newSocket = io(restbaseurl);
    newSocket.on('connect', () => {
      console.log('Socket connected---> home');
    });

    newSocket.on('tripAcceptedNotification', (data) => {
      if (data?.tripDetails?.customerId === userData?.userId) {
        setTripInfo(data?.tripDetails);
      }
    });
    
    newSocket.on('disconnect', () => {
      console.log('Socket disconnected ---home');
    });

    return () => {
      newSocket.off('tripAcceptedNotification---home');
    };
  }, []);

  const fetchLocations = useCallback(
    debounce((query, name) => {
      const locationInfo = localStorage.getItem("userLocation");
      const storedLocation = JSON.parse(locationInfo);

      if (storedLocation?.lng) {
        GlobalService.generalSelect(
          (respdata) => {
            const { data, message } = respdata;
            if (message === "successful") {
              setLocations(data);
              setPosition((prevPosition) => ({
                ...prevPosition,
                [name]: storedLocation.position,
              }));
            }
          },
          resturls.obtainLocationList,
          { input: query, lat: storedLocation?.lat, lng: storedLocation?.lng, type: name },
          "POST"
        );
      }
    }, 300),
    []
  );

  const handleLocationClick = (location) => {
    if (focusedField) {
      setInput((prevInput) => ({ ...prevInput, [focusedField]: location?.address }));
      setPosition((prevPosition) => ({
        ...prevPosition,
        [focusedField]: location?.position,
      }));
    }
    setLocations([]);
  };

  const handleBookTrip = () => {
    const obj = {
      originLat: position?.from?.lat,
      originLng: position?.from?.lng,
      destinationLat: position?.to?.lat,
      destinationLng: position?.to?.lng,
      userId: userData?.userId,
      rideType,
    };

    setBooking(true);

    GlobalService.generalSelect(
      (respdata) => {
        const { data, message } = respdata;
        if (message === "successful") {
          history.push(`/bookingScreen`);
          setBooking(data);
        }
      },
      resturls.checkDistance,
      obj,
      "POST"
    );
  };

  const handleTripType = (type) => {
    setRideType(type);
    setStep(2); // Move to the next step
  };

  const capitalizeFirstLetter = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  // New render logic using switch-case for different steps
  const renderModalContent = () => {
    switch (step) {
      case 1:
        return (
          <div className={style.selectTripType}>
            <h4>Select Trip Type</h4>
            <div className={style.contentWrapper}>
              <div onClick={() => handleTripType('car')} className={`${rideType === 'car' ? style.activeType : ''} ${style.imageWrapper}`}><Image size="medium" src={carImg} /></div>
              <div onClick={() => handleTripType('driver')}  className={`${rideType === 'driver' ? style.activeType : ''} ${style.imageWrapper}`}><Image size="medium" src={driverImg} /></div>
            </div>
          </div>
        );
      case 2:
        return <CustomDateTimePicker setSchedule={setSchedule} setStep={() => setStep(3)} />;
      case 3:
        return (
          <div className={style.locationWrapper}>
            <h4>Select Address</h4>
            <hr />
            <div className={style.inputWrapper}>
              <Input
                placeholder="From"
                value={input.from}
                onChange={handleInputChange}
                icon={fromIcon()}
                onFocus={() => setFocusedField("from")}
                className={style.inputField}
                name="from"
              />
              <Input
                placeholder="To"
                value={input.to}
                onChange={handleInputChange}
                icon={toIcon()}
                onFocus={() => setFocusedField("to")}
                className={style.inputField}
                name="to"
              />
            </div>
            <div>
              {locationList.map((data, i) => (
                <div key={`locationOf${i}`} onClick={() => handleLocationClick(data)} className={style.locationInfo}>
                  {toIcon()}
                  <div>
                    <h5>{data.name}</h5>
                    <p>{data.address}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={style.buttonWrapper}>
              <button onClick={handleBookTrip}>Book now</button>
            </div>
          </div>
        );
      default:
        return <div>Invalid step</div>;
    }
  };

  const renderBookingProcess = () => (
    tripInfo !== false ? (
      <div className={style.activeRide}>
        {/* Render trip details */}
        <Segment className={style.tripCard}>
          <h5>TripId : {tripInfo?.tripId}</h5>
          <div className={style.tripLoaction}>
            <h6>From</h6><p>{tripInfo?.pickupLocation?.address}</p>
          </div>
          <div className={style.tripLoaction}>
            <h6>To</h6><p>{tripInfo?.dropoffLocation?.address}</p>
          </div>
          <div className={style.tripLoaction}>
            <h6>Cost</h6><p>Rs.{tripInfo?.fare} /-</p>
          </div>
        </Segment>
        <div className={style.bookedInfo}>
          <div className={style.mapWrapper}>
            <Image src={mapImage} size="massive" />
          </div>
          <Segment className={style.driverInfoCard}>
            <div className={style.leftInfo}>
              <Avatar className={style.userAvatar} size="85" round={true} name={capitalizeFirstLetter(tripInfo?.driver?.username)} />
              <div>
                <p>Driver</p>
                <h5>{tripInfo?.driver?.username}</h5>
                <p>ID: {tripInfo?.driverId}</p>
              </div>
            </div>
            <div className={style.rightInfo}>
              <Icon name="phone volume" size="huge" />
              <div>
                <p>Contact No</p>
                <h5>{tripInfo?.driver?.mobileNumber}</h5>
              </div>
            </div>
          </Segment>
        </div>
      </div>
    ) : (
      <div>
        <div className={style.textMessage}>
          <h2>Searching for a driver... Your request has been sent, and we're just waiting for a driver to accept. Thank you for your patience!</h2>
        </div>
        <CarLoader />
      </div>
    )
  );

  return (
    <>
      <Header />
      {
        booking !== false ? renderBookingProcess() :
        <div className={style.mainContainer}>{renderModalContent()}</div>
      }
    </>
  );
};

export default BookingScreen;
