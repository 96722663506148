import RestDataSource from '../global/utils/RestDataSource';
// import { resturls } from '../global/utils/apiurls';

class GlobalService {
  static generalSelect = (callback, url = '', values = {}, method = 'GET', urlType = 'api') => {
    console.log(callback, 'generalSelect');
    RestDataSource.GetData((respdata) => {
      callback(respdata);
    }, url, values, method, urlType);
  };

  static generalUpdate = (callback, url = '', values = {}) => {
    RestDataSource.Update((respdata) => {
      callback(respdata);
    }, url, values);
  };

  static generalSave = (callback, url = '', values = {}) => {
    RestDataSource.Save((respdata) => {
      callback(respdata);
    }, url, values);
  };

  static generalDelete = (callback, url = '', values = {}) => {
    RestDataSource.Delete((respdata) => {
      callback(respdata);
    }, url, values);
  }

  static crossDomainRequest = (callback, url, values = {}, method = 'GET', additionalReqParams = {}) => {
    RestDataSource.crossDomainRequest((respdata) => callback(respdata),
      method, url, values, additionalReqParams);
  }

}
export default GlobalService;
