import React, { useEffect, useState } from 'react';
import {
  FaThLarge,
  FaUsers,
  FaUser,
  FaCar,
  FaCreditCard,
  FaFileAlt,
  FaSignOutAlt,
  FaEnvelope,
  FaBell,
  FaUserCircle,
} from 'react-icons/fa';
import style from './less/dashboard.module.scss';
import AreaSplineChart from './AreaSplineChart';
import CircleProgress from './CircleProgress';
import GlobalService from "./services/GlobalService";
import { resturls } from "./global/utils/apiurls";
import { Table } from 'semantic-ui-react';

const dashboardData = {

  sidebarItems: [
    { icon: <FaThLarge />, label: 'Dashboard', id: 0 },
    { icon: <FaUsers />, label: 'Customer', id: 1},
    { icon: <FaUser />, label: 'Drivers', id: 2 },
    { icon: <FaCar />, label: 'Cars',},
    { icon: <FaCreditCard />, label: 'Payment', },
    { icon: <FaFileAlt />, label: 'Reports', },
    { icon: <FaSignOutAlt />, label: 'Logout', },
  ],
  topBarIcons: [<FaEnvelope />, <FaBell />, <FaUserCircle />],
  statistics: [
    { title: 'BOOK TRIPS', value: '65', change: '+36.67%', positive: true },
    // { title: 'CANCELED TRIPS', value: '257', change: '-16.67%', positive: false },
    { title: 'AVAILABLE DRIVERS', value: '50', change: '+43.67%', positive: true },
    { title: 'AVAILABLE RIDERS', value: '32', change: '+26.67%', positive: true },
  ],
  graphData: {
    title: 'Active Drivers By Time',
  },
  visits: [
    { label: 'App Visits', value: '56748' },
    { label: 'Active Visits', value: '56748' },
  ],
  earnings: {
    total: '325678.00',
    previous: ['728678.00', '525678.00', '356678.00', '125678.00'],
  },
  topDrivers: [
    'Prame Kumar R',
    'Ramesh R',
    'Ravi kumar T',
    'Rajesh T',
  ],
  topCars: [
    'BMW Cabrio',
    'Mustang Shelby GT',
    'BMW i8',
    'BMW Cabrio',
  ],
};

const Dashboard = () => {

  const [ dataList, setdataList ] = useState([]);
  const [ activeMenu, setActiveMenu ] = useState(0);

  useEffect(() => {
    GlobalService.generalSelect(
      (respdata) => {
        const { data, message } = respdata;
        if (message === "success") {
           console.log(data, 'data');
           setdataList(data);
        }
      },
      resturls.obtainUserList,
      {},
      "POST"
    );
  }, []);


  const renderGraphDetails = () => (
    <div className={style.detailsSection}>
          <div className={style.graph}>
            <h4>{dashboardData.graphData.title}</h4>
            <div className={style.graphArea}>
            <AreaSplineChart />
            
            </div>
          </div>

          <div className={style.visits}>
            <h4>Visits</h4>
            <div className={style.visitGraphContiner}>

            {dashboardData.visits.map((visit, index) => (
              <div key={index} className={style.visitGraph}>
                
                <CircleProgress />
                {/* <div>{visit.label}</div>
                <div>{visit.value}</div> */}
              </div>
            ))}
            </div>
          </div>

          {/* Earnings */}
          <div className={style.earnings}>
            <h4>Total Earning</h4>
            <h5>{dashboardData.earnings.total}</h5>
            <div className={style.earningsList}>
              <p>Previous Earning</p>
              {dashboardData.earnings.previous.map((amount, index) => (
                <p key={index}>{amount}</p>
              ))}
            </div>
          </div>

          {/* Top Drivers */}
          <div className={style.topDrivers}>
            <h4>Top Drivers</h4>
            <ul>
              {dashboardData.topDrivers.map((driver, index) => (
                <li key={index}>{driver}</li>
              ))}
            </ul>
          </div>

          {/* Top Cars */}
          <div className={style.topCars}>
            <h4>Top Cars</h4>
            <ul>
              {dashboardData.topCars.map((car, index) => (
                <li key={index}>{car}</li>
              ))}
            </ul>
          </div>
        </div>
  )

  const handleActiveMenu = (id) => {
    console.log(id, 'id');
    
    if(id !== undefined){
      setActiveMenu(id);
    }
  }

  const renderMainContent = () => {
    console.log(dataList, 'dataList');
    
    if(activeMenu === 1){
     return(
      <div className={style.tableContainer}>
         <Table celled striped padded>
           <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>User Id</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Contact No</Table.HeaderCell>
            </Table.Row>
           </Table.Header>
           <Table.Body>
              {
                dataList?.users?.map((list, idx) => (
                  <Table.Row>
                     <Table.Cell>{idx + 1}</Table.Cell>
                     <Table.Cell>{list?.userName}</Table.Cell>
                     <Table.Cell>{list?.userId}</Table.Cell>
                     <Table.Cell>{list?.role}</Table.Cell>
                     <Table.Cell>{list?.mobileNumber}</Table.Cell>
                   </Table.Row>
                ))
              }
           </Table.Body>
         </Table>
       </div>
     )
    }

    if(activeMenu === 2){
      return(
       <div className={style.tableContainer}>
         <Table celled striped padded>
           <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>User Id</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Contact No</Table.HeaderCell>
              <Table.HeaderCell>Active Status</Table.HeaderCell>
            </Table.Row>
           </Table.Header>
           <Table.Body>
              {
                dataList?.drivers?.map((list, idx) => (
                  <Table.Row>
                     <Table.Cell>{idx + 1}</Table.Cell>
                     <Table.Cell>{list?.userName}</Table.Cell>
                     <Table.Cell>{list?.userId}</Table.Cell>
                     <Table.Cell>{list?.role}</Table.Cell>
                     <Table.Cell>{list?.mobileNumber}</Table.Cell>
                     <Table.Cell className={style.statusCell}>{ <span className={list?.status === "available" ? style.greenDot : style.redDot}></span>}{list?.status}</Table.Cell>
                   </Table.Row>
                ))
              }
           </Table.Body>
         </Table>
       </div>
      )
     }
    return (
      <div className={style.mainContent}>
        <div className={style.topBar}>
          <input type="text" className={style.searchInput} placeholder="Type To Search" />
          <div className={style.topBarIcons}>
            {dashboardData.topBarIcons.map((icon, index) => (
              <span key={index}>{icon}</span>
            ))}
          </div>
        </div>

        <div className={style.statistics}>
          {dashboardData.statistics.map((stat, index) => (
            <div key={index} className={style.statCard}>
              <h4>{stat.title}</h4>
              <h2>{stat.value}</h2>
              <span className={stat.positive ? style.statChangePositive : style.statChangeNegative}>
                {stat.change}
              </span>
            </div>
          ))}
        </div>
    </div>
    );
  }


  return (
    <div className={style.dashboard}>
      <div className={style.sidebar}>
        {dashboardData.sidebarItems.map((item, index) => (
          <div key={index} className={`${style.sidebarItem} ${item?.id === activeMenu ? style.activeMenu : ''}`} onClickCapture={() => handleActiveMenu(item?.id)}>
            {item.icon} {item.label}
          </div>
        ))}
      </div>
      {
        renderMainContent()
      }
    </div>
  );
};

export default Dashboard;
