import React from "react";
import image from "../../assets/Images/comingSoon.png";
import Header from "./Header";
import { Image } from "semantic-ui-react";


const ComingSoon = () => {
  return (
    <div>
      <Header />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "79vh" }}>
        <Image src={image} alt="coming Soon" width="550px" />
      </div>
    </div>
  );
};

export default ComingSoon;
