import React from "react";
import style from "./less/peopleConnection.module.scss";
import circleImage from "../assets/Images/circlePeople.png"

const PeopleConnection = () => {

  return (
    <div className={style.peopleConnection}>
      <div className={style.leftContainer}>
        <img src={circleImage} alt="circleImage" />
      </div>
      <div className={style.rightContainer}>
        <h1>Connected with all circles and wherever and whenever</h1>
        <p>Raj on call Driver Service reaches all people so that they can use the facilities that we provide, very special comfort and security</p>
        <p className={style.exploreBtn}>Explore now</p>
      </div>
    </div>
  );
};

export default PeopleConnection;
