// Introduction.js
import React, { useState } from "react";
import style from "./less/introduction.module.scss";
import { Input } from "semantic-ui-react";
import car from "../assets/Images/carFrame.png";
import plant from "../assets/Images/Plants.png";
import locationIndigate from "../assets/Images/placeIndicate.png";
import { useHistory } from "react-router-dom";
import { calenderIcon, oneWay, roundTrip, outStation } from "./global/IconsList";

const Introduction = () => {
  const [selectedMode, setSelectedMode] = useState("transport");
  const history = useHistory();

  const subMenuList = [
    { text: "One Way", icon: oneWay() },
    { text: "Round Trip", icon: roundTrip() },
    { text: "Outstation", icon: outStation() },
    { text: "Daily", icon: calenderIcon() },
  ];

  const renderSubMenuList = () =>
    subMenuList.map((data) => (
      <div key={data.text}>
        {data.icon}
        <p>{data.text}</p>
      </div>
    ));

    const handleRedirection = () => {
      history.push('/bookingScreen')
    }

  return (
    <>
      <div className={style.mainContentWrapper}>
        <div className={style.contentWrapper}>
          <div className={style.leftContainer}>
            <div className={style.descContainer}>
              <h1>Be the first to enjoy convenience of Raj Call Driver Service</h1>
            </div>
            <div className={style.selectionContainer}>
              <div className={style.subMenuList}>{renderSubMenuList()}</div>
            </div>
            <div className={style.locationSelectionContainer}>
              <Input icon="search" placeholder="Where to?" className={style.inputField} onFocus={() => handleRedirection()} />
              <div className={style.modeSelection}>
                <p
                  className={selectedMode === "transport" && style.activeMode}
                  onClick={() => setSelectedMode("transport")}
                >
                  Transport
                </p>
                <p className={selectedMode === "driver" && style.activeMode} onClick={() => setSelectedMode("driver")}>
                  Driver
                </p>
              </div>
            </div>
          </div>
          <div className={style.rightContainer}>
            <img className={style.locationIndigate} src={locationIndigate} alt="locationIndigate" />
            <img className={style.carImg} src={car} alt="car" />
            <div className={style.locationWrapper}>
              <img alt="plant" src={plant} />
              <h2>BANGALORE, KARNATAKA</h2>
            </div>
          </div>
        </div>
      </div>
      {/* <BookingScreen modal={modal} setModal={setModal} history={history} userData={userData} /> */}
    </>
  );
};

export default Introduction;
