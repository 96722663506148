import React from 'react';
import style from './less/ourCustomer.module.scss';

const testimonials = [
  {
    name: 'Jane Cooper',
    location: 'Curug',
    testimonial: 'This TaxiGO is very cool, very friendly, fast and very friendly and good service',
    rating: 5
  },
  {
    name: 'Leslie Alexander',
    location: 'Indonesia',
    testimonial: 'A very comfortable and safe taxi, with super friendly and clear service at a very affordable price',
    rating: 5
  },
  {
    name: 'Cody Fisher',
    location: 'Bulgarian',
    testimonial: 'It must have been very comfortable with this TaxiGo service, cheap, friendly, affordable',
    rating: 5
  }
];

const ourCustomer = () => {
  return (
    <>
    <div className={style.check}>
      <div className={style.parent}>
        <h2 className={style.head}>What Our Customers<br/>Have To Say</h2>
        <p className={style.topic}>Here's what our customers say with CareHealth</p>
      </div>
      <div className={style.funFactCards}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className={style.testimonialCard}>
            <div className={style.cardHeader}>
              <div className={style.avatar}></div>
              <div>
                <h4 className={style.name}>{testimonial.name}</h4>
                <p className={style.location}>{testimonial.location}</p>
              </div>
            </div>
            <p className={style.testimonial}>{testimonial.testimonial}</p>
            <div className={style.rating}>
              {Array.from({ length: testimonial.rating }).map((_, starIndex) => (
                <span key={starIndex} className={style.star}>★</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default ourCustomer;
