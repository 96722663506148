import React from 'react';
import style from './less/pricing.module.scss';

const data = [
    {
      "title": "Basic",
      "description": "Vestibulum ac ac ultricies sagittis ligula venenatis enim a.",
      "features": [
        "Clinic Lighting Control",
        "Smart Thermostat Control",
        "Voice Control Integration",
        "Mobile App Control",
        "Basic Security Integration"
      ],
      "price": "$1,640"
    },
    {
      "title": "Advanced",
      "description": "Vestibulum ac ac ultricies sagittis ligula venenatis enim a.",
      "features": [
        "Smart Lighting Control",
        "Smart Thermostat Control",
        "Voice Control Integration",
        "Mobile App Control",
        "Advanced Security Integration"
      ],
      "price": "$2,480"
    },
    {
      "title": "Superior",
      "description": "Vestibulum ac ac ultricies sagittis ligula venenatis enim a.",
      "features": [
        "Smart Lighting Control",
        "Smart Thermostat Control",
        "Voice Control Integration",
        "Mobile App Control",
        "Super Security Integration"
      ],
      "price": "$3,990"
    }
  ];
  const CheckIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_67_3321)">
        <path d="M7.9987 14.6668C11.6806 14.6668 14.6654 11.6821 14.6654 8.00016C14.6654 4.31826 11.6806 1.3335 7.9987 1.3335C4.3168 1.3335 1.33203 4.31826 1.33203 8.00016C1.33203 11.6821 4.3168 14.6668 7.9987 14.6668Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 10.6668L10.6667 8.00016L8 5.3335" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.33203 8H10.6654" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_67_3321">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  );
const Pricing = () => {
    return (
        <div className={style.pricingTable}>
        <div className={style.priceTable}>Pricing Table</div>
        <div className={style.title}>
          Discover Our Transparent and Flexible Pricing Options
        </div>
        <div className={style.cards}>
          {data.map((item, index) => (
            <div key={index} className={style.card}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <ul>
                {item.features.map((feature, idx) => (
                    <div className={style.feature}>
                      <li key={idx}><CheckIcon/> {feature}</li>
                    </div>
                ))}
              </ul>
              <p className={style.price}>Starting from {item.price}</p>
              <div className={style.btnWrapper}>
              <button>Get Started</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Pricing;