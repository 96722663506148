import React from 'react';
import error500 from "../assets/Images/error/500.png";
import { Image } from 'semantic-ui-react';

const Error500 = () => {

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
    <Image size='massive' src={error500} />
    </div>
  );
};

export default Error500;
