import React from "react";
import style from "./less/footer.module.scss";

const GitHub = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_0_7126)">
      <path
        d="M9 18.9999C4 20.4999 4 16.4999 2 15.9999M16 21.9999V18.1299C16.0375 17.6531 15.9731 17.1737 15.811 16.7237C15.6489 16.2737 15.3929 15.8634 15.06 15.5199C18.2 15.1699 21.5 13.9799 21.5 8.51994C21.4997 7.12376 20.9627 5.78114 20 4.76994C20.4559 3.54844 20.4236 2.19829 19.91 0.999938C19.91 0.999938 18.73 0.649938 16 2.47994C13.708 1.85876 11.292 1.85876 9 2.47994C6.27 0.649938 5.09 0.999938 5.09 0.999938C4.57638 2.19829 4.54414 3.54844 5 4.76994C4.03013 5.78864 3.49252 7.1434 3.5 8.54994C3.5 13.9699 6.8 15.1599 9.94 15.5499C9.611 15.8899 9.35726 16.2953 9.19531 16.7399C9.03335 17.1844 8.96681 17.658 9 18.1299V21.9999"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_7126">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Instagram = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 11.3698C16.1234 12.2021 15.9812 13.052 15.5937 13.7988C15.2062 14.5456 14.5931 15.1512 13.8416 15.5295C13.0901 15.9077 12.2384 16.0394 11.4077 15.9057C10.5771 15.7721 9.80971 15.3799 9.21479 14.785C8.61987 14.1901 8.22768 13.4227 8.09402 12.592C7.96035 11.7614 8.09202 10.9097 8.47028 10.1582C8.84854 9.40667 9.45414 8.79355 10.2009 8.40605C10.9477 8.01856 11.7977 7.8764 12.63 7.99981C13.4789 8.1257 14.2648 8.52128 14.8716 9.12812C15.4785 9.73496 15.8741 10.5209 16 11.3698Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 6.5H17.51"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Twitter = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0.999802C21.0424 1.67528 19.9821 2.19191 18.86 2.5298C18.2577 1.83731 17.4573 1.34649 16.567 1.12373C15.6767 0.900962 14.7395 0.956997 13.8821 1.28426C13.0247 1.61151 12.2884 2.1942 11.773 2.95352C11.2575 3.71283 10.9877 4.61214 11 5.5298V6.5298C9.24263 6.57537 7.50127 6.18561 5.93101 5.39525C4.36074 4.60488 3.01032 3.43844 2 1.9998C2 1.9998 -2 10.9998 7 14.9998C4.94053 16.3978 2.48716 17.0987 0 16.9998C9 21.9998 20 16.9998 20 5.4998C19.9991 5.22126 19.9723 4.9434 19.92 4.6698C20.9406 3.6633 21.6608 2.39251 22 0.999802Z"
      fill="white"
    />
  </svg>
);

const Facebook = () => (
  <svg
    width="11"
    height="20"
    viewBox="0 0 11 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0H8C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V8H0V12H3V20H7V12H10L11 8H7V5C7 4.73478 7.10536 4.48043 7.29289 4.29289C7.48043 4.10536 7.73478 4 8 4H11V0Z"
      fill="white"
    />
  </svg>
);

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footerContainer}>
        {/* <div className={`${style.footerSection}${style.companyContent}`}> */}
        <div className={style.footerSection}>
          <div className={style.companyContent}>
            <h2>TaxiGO</h2>
            <p>
            Taxigo Comes with a very great service, friendly and of course comfortable at a very affordable price by all groups, both bottom and top
            </p>
          </div>
        </div>
        <div className={style.footerRightPane}>
          <div className={`${style.footerInfo} ${style.typeOne}`}>
            <h3>Office</h3>
            <ul>
              <li>
                <span>Jln.Galau terus no 23. 
                Jakarta selatan</span>
              </li>
              <li>
              <h3>Follow us</h3>
              <div className={style.footerSocialIcons}>
                <span className={style.footerSocialIcon}>
                  <Twitter />
                </span>
                <span className={style.footerSocialIcon}>
                  <Facebook />
                </span>
                <span className={style.footerSocialIcon}>
                  <Instagram />
                </span>
                <span className={style.footerSocialIcon}>
                  <GitHub />
                </span>
            </div>
              </li>
            </ul>
          </div>
          <div className={`${style.footerInfo} ${style.typeOne}`}>
            <h3>About Us</h3>
            <ul>
              <li>
                <span>Our taxi’s</span>
              </li>
              <li>
                <span>Our drivers</span>
              </li>
              <li>
                <span>Contact us</span>
              </li>
              <li>
                <span>Testimonial</span>
              </li>
            </ul>
          </div>
          <div className={`${style.footerInfo} ${style.typeTwo}`}>
            <h3>Info</h3>
            <ul>
              <li>
                <span>FAQ</span>
              </li>
              <li>
                <span>Cookie & help</span>
              </li>
              <li>
                <span>Privacy policy</span>
              </li>
              <li>
                <span>Terms & conditions</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={style.footerBottom}>
        <p>Copyright © 2024 Raj Call Driver Service.</p>
      </div>
    </footer>
  );
};

export default Footer;
