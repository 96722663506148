/* eslint-disable react/jsx-props-no-spreading */
import Cookies from "js-cookie";
// import UserInfo from 'models/UserInfo';
import GlobalService from "../../services/GlobalService";
import { resturls } from "./apiurls";
import { cookiedomain } from "./constants";
import ls from "local-storage";
// import iocLogo from 'assets/images/favIconIoc.svg';

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );
  return JSON.parse(jsonPayload);
}

function updateC1() {
  const expmin = new Date(new Date().getTime() + 20 * 60 * 1000);
  const val1 = Cookies.get("_c1", { domain: cookiedomain });
  if (val1 !== undefined && val1 !== null) {
    Cookies.remove("_c1", { domain: cookiedomain });
    Cookies.set("_c1", val1, {
      path: "/",
      expires: expmin,
      domain: cookiedomain,
    });
  }
}

function updateUserInfo(c1 = null) {
  let val1 = c1;
  const ck = Cookies.get("_c1", { domain: cookiedomain });
  console.log("Cookie Domain", cookiedomain, ck);
  if (ck != null) {
    val1 = ck;
    const now = new Date();
    const cookieObj = {
      data: val1,
      expiry: now.getTime() + 1200000,
    };
    ls.set("_c1", cookieObj);
    console.log("Thru cookie");
  } else if (c1 !== undefined && c1 !== null) {
    const now = new Date();
    const cookieObj = {
      data: c1,
      expiry: now.getTime() + 1200000,
    };
    ls.set("_c1", cookieObj);
    console.log("Thru Params - post login");
  }
  if (val1 === undefined || val1 === null) {
    const cookieObj = ls.get("_c1");
    if (cookieObj !== undefined && cookieObj !== null) {
      const now = new Date();
      if (now.getTime() <= cookieObj.expiry) {
        val1 = cookieObj.data;
        cookieObj.expiry = now.getTime() + 1200000;
        ls.set("_c1", cookieObj);
      } else {
        ls.remove("_c1");
        window.localStorage.setItem("isEventClosed", "");
      }
    }
    console.log("Thru Local Store");
  }

  if (val1 !== undefined && val1 !== null && val1) {
    // const data = parseJwt(val1);
    // UserInfo.setUserDetail(data);
  } else {
    console.log("updateUserInfo - Cookie / LS / Params not found");
  }
}

function clearCookies() {
  let ck = Cookies.get("_c1", { domain: cookiedomain });
  console.log("before logout ck", ck, cookiedomain);
  Cookies.remove("_c1", { domain: cookiedomain });
  ck = Cookies.get("_c1", { domain: cookiedomain });
  ls.remove("_c1");
  document.cookie = "_c1=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  document.location.href = "/login";
  window.localStorage.setItem("isEventClosed", "");
  window.localStorage.setItem("authAmphiSessionId", undefined);
}

function processLogout(redirectToLogin = true) {
  const obj = {};
  GlobalService.generalSelect(
    (respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage) {
        if (data.status === "logged out") {
          let ck = Cookies.get("_c1", { domain: cookiedomain });
          console.log("before logout ck", ck, cookiedomain);
          Cookies.remove("_c1", { domain: cookiedomain });
          ls.remove("schoolId");
          ls.remove("schoolName");
          ck = Cookies.get("_c1", { domain: cookiedomain });
          ls.remove("_c1");
          ls.remove("institutionId");
          ls.remove("domain");
          ls.remove("activePrgmScreen");
          ls.remove("roleDetailsMap");
          ls.remove("defaultRedirection");
          ls.remove("trackData");
          document.cookie =
            "_c1=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          if (redirectToLogin) {
            document.location.href = "/login";
          }
          //   UserInfo.clear();
          window.localStorage.setItem("isEventClosed", "");
          window.localStorage.setItem("authAmphiSessionId", undefined);
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("loggedOut");
            window.ReactNativeWebView.postMessage("loggedOut");
          }
        }
      }
    },
    resturls.logout,
    obj,
    "POST"
  );
}

// function PrivateRoute({ component: Component, cprops, ...rest }) {
//   const valid = (UserInfo.isAuth() !== undefined
//     && UserInfo.isAuth() !== null
//     && UserInfo.isAuth() === true);
//   return (
//     <Route
//       {...rest}
//       render={(props) => ((valid === true)
//         ? <Component {...cprops} {...props} />
//         : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: props.location },
//             }}
//           />
//         ))}
//     />
//   );
// }

function updateFavIcon() {
  const favicon = document.getElementById("favicon");
  GlobalService.generalSelect((respdata) => {
    const {
      estatus,
      emessage,
      data: {
        faviconLink,
        titleContent,
        roleDetailsMap,
        defaultRedirection,
        domainTheme,
        isTrank,
      },
    } = respdata;
    //   favicon.href = iocLogo;
    document.title = "Image of a Child";
    if (estatus === true && emessage === "success") {
      if (faviconLink !== "") {
        favicon.href = faviconLink;
        document.title = titleContent;
      }
      ls.set("roleDetailsMap", roleDetailsMap);
      ls.set("defaultRedirection", defaultRedirection);
      ls.set("isTrank", isTrank);
      ls.set("domainTheme", domainTheme);
    }
  }, resturls.obtainFavIconAndTitle);
}

function updateTheme(id = null) {
  let courseId = id;
  if (id === null) {
    courseId =
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ];
  }
  const domainTheme = ls.get("domainTheme");
  if (domainTheme?.length > 0) {
    try {
      const jsonObject = JSON.parse(domainTheme);
      const themeName = Object.keys(jsonObject).find((eleName) =>
        jsonObject[eleName].find((eleId) => Number(eleId) === Number(courseId))
      );
      const bodyTagEle = document.getElementsByTagName("body")[0];
      if (
        themeName !== null &&
        themeName !== undefined &&
        themeName.length > 0
      ) {
        bodyTagEle.classList = [];
        bodyTagEle.classList.add(themeName);
      }
    } catch (e) {
      console.error(e);
    }
  }
}


export {
  parseJwt,
  updateC1,
  updateUserInfo,
  //    PrivateRoute,
  processLogout,
  clearCookies,
  updateFavIcon,
  updateTheme,
};
