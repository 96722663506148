import React from 'react';
import error401 from "../assets/Images/error/401.png";
import { Image } from 'semantic-ui-react';

const Error401 = () => {

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Image size='massive' src={error401} />
    </div>
  );
};

export default Error401;
