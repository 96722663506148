import React, { useEffect, useState } from "react";
import Header from "./global/Header";
import style from "./less/homeScreen.module.scss";
import Footer from "./Footer";
import Introduction from "./Introduction";
import SpecialServices from "./SpecialServices";
import PeopleConnection from "./PeopleConnection";
import QuestionAnswers from "./QuestionAnswers"
import OurCustomer from "./ourCustomer";
// import { io } from 'socket.io-client';
// import { restbaseurl } from "./global/utils/constants"

const HomeScreen = ({ location }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [modal, setModal] = useState(false);

  // useEffect(() => {
  //   // Listen for the trip acceptance notification
  //   // const newSocket = io('http://localhost:4000');
  //   const newSocket = io(restbaseurl);

  //   newSocket.on('connect', () => {
  //     console.log('Socket connected---> home');
  //   });

  //   newSocket.on('tripAcceptedNotification', (data) => {
  //     console.log('Trip accepted notification received----home:', data);
  //     // You can handle the notification (e.g., show an alert, update UI, etc.)
  //     console.log(data, 'response--->home')
  //     if(data?.tripDetails?.customerId === userData?.userId ){
  //      alert('confirmed Booking')
  //     }
  //   });
  //   newSocket.on('disconnect', () => {
  //     console.log('Socket disconnected ---home');
  //   });
  //   // Cleanup on component unmount
  //   return () => {
  //     newSocket.off('tripAcceptedNotification---home');
  //   };
  // }, []);
  return (
    <>
      <div className={style.mainContainer}>
        <Header searchBar />
        <Introduction location={location} modal={modal} setModal={setModal}/>
        <SpecialServices  modal={modal} setModal={setModal}  />
        <PeopleConnection />
        <OurCustomer />
        <QuestionAnswers />
        <Footer />
      </div>
    </>
  );
};

export default HomeScreen;
